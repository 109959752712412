import { defineStore } from "pinia";
import { useSearchStore } from "./search";
import ChartState, {
  MentionChartData,
  ConvertedArticleSentimentChartData,
  HighchartDataFormt,
} from "~/types/ChartsType";
import {
  ArticleSentimentChartData,
  ArticleChartData,
  CountByMediaType,
} from "~/types/attributes/ChartDataAttributesType";
import RequestOptions from "~/types/RequestOptionsType";
import { useApiCall } from "~/composables/useApiCall";
import { useRequestQuery } from "~/composables/useRequestQuery";

export const useChartsStore = defineStore("charts", {
  state: (): ChartState => ({
    dashboardChartsData: [], // all charts data from each profile
    shareOfVoiceData: [],
    shareOfVoiceOverTimeData: [],
    shareOfVoiceTotal: 0,
    comparisonCoverageData: [],
    totalSentiment: { negative: 0, positive: 0, neutral: 0 },
    sentimentLineChartData: [],
    shareOfReachData: [],
    shareOfReachOverTimeData: [],
    shareOfAmplificationData: [],
    shareOfAmpOverTimeData: [],
    reachByMediaTypeData: {
      print: 0,
      radio: 0,
      tv: 0,
      web: 0,
    },
    articleCountByMediaTypeData: {
      print: 0,
      radio: 0,
      tv: 0,
      web: 0,
    },
  }),
  getters: {
    shareOfVoice: (state) => {
      return state.shareOfVoiceData.length > 0
        ? [
            {
              type: "pie",
              name: "Mentions",
              data: state.shareOfVoiceData,
            },
          ]
        : [{ type: "pie", name: "Mentions", data: [] }];
    },
    shareOfVoiceSum: (state) => {
      return state.shareOfVoiceTotal;
    },
    shareOfVoiceOverTime: (state) => {
      return state.shareOfVoiceOverTimeData.length > 0
        ? state.shareOfVoiceOverTimeData
        : null;
    },
    shareOfReach: (state) => {
      return state.shareOfReachData.length > 0
        ? [
            {
              type: "pie",
              name: "Reach",
              data: state.shareOfReachData,
            },
          ]
        : [{}];
    },
    shareOfReachOverTime: (state) => {
      return state.shareOfReachOverTimeData.length > 0
        ? state.shareOfReachOverTimeData
        : null;
    },
    shareOfAmplification: (state) => {
      return state.shareOfAmplificationData.length > 0
        ? [
            {
              type: "pie",
              name: "Amplification",
              data: state.shareOfAmplificationData,
            },
          ]
        : [{}];
    },
    shareOfAmpOverTime: (state) => {
      return state.shareOfAmpOverTimeData.length > 0
        ? state.shareOfAmpOverTimeData
        : null;
    },
    comparisonCoverage: (state) => {
      return state.comparisonCoverageData.length > 0
        ? state.comparisonCoverageData
        : null;
    },
    sentimentChartSeries: (state) => {
      return [
        {
          type: "pie",
          name: "Articles",
          data: [
            {
              name: "Positive",
              y: state.totalSentiment.positive,
              color: "#14AE49",
            },
            {
              name: "Neutral",
              y: state.totalSentiment.neutral,
              color: "#D4D4D8",
            },
            {
              name: "Negative",
              y: state.totalSentiment.negative,
              color: "#FB8989",
            },
          ],
        },
      ];
    },
    sentimentOverTime: (state) => {
      const positives: HighchartDataFormt = {
        data: [],
        name: "Positive",
        type: "line",
        color: "#14AE49",
      };
      const neutrals: HighchartDataFormt = {
        data: [],
        name: "Neutral",
        type: "line",
        color: "#D4D4D8",
      };
      const negatives: HighchartDataFormt = {
        data: [],
        name: "Negative",
        type: "line",
        color: "#FB8989",
      };
      if (state.sentimentLineChartData.length > 0) {
        // sort the sentiment by date first or chart breaks.
        state.sentimentLineChartData.sort(
          (
            a: ConvertedArticleSentimentChartData,
            b: ConvertedArticleSentimentChartData,
          ) => a.date - b.date,
        );
        state.sentimentLineChartData.forEach(
          (lineData: ConvertedArticleSentimentChartData) => {
            positives.data.push([lineData.date, lineData.positive]);
            neutrals.data.push([lineData.date, lineData.neutral]);
            negatives.data.push([lineData.date, lineData.negative]);
          },
        );
      }

      return [positives, neutrals, negatives];
    },
    reachByMediaType: (state) => {
      return [
        {
          type: "pie",
          name: "Mentions",
          data: [
            {
              name: "Web",
              y: state.reachByMediaTypeData.web,
            },
            {
              name: "Print",
              y: state.reachByMediaTypeData.print,
            },
            {
              name: "Tv",
              y: state.reachByMediaTypeData.tv,
            },
            {
              name: "Radio",
              y: state.reachByMediaTypeData.radio,
            },
          ],
        },
      ];
    },
    articleCountByMediaType: (state) => {
      return [
        {
          type: "pie",
          name: "Mentions",
          data: [
            {
              name: "Print",
              y: state.articleCountByMediaTypeData.print,
            },
            {
              name: "Radio",
              y: state.articleCountByMediaTypeData.radio,
            },
            {
              name: "Tv",
              y: state.articleCountByMediaTypeData.tv,
            },
            {
              name: "Web",
              y: state.articleCountByMediaTypeData.web,
            },
          ],
        },
      ];
    },
  },
  actions: {
    async fetchDashboardChartData() {
      this.resetToDefault();
      const savedSearchStore = useSearchStore();
      savedSearchStore.updateQuerySearchType(1);
      for (const profile of savedSearchStore.nestedSearches) {
        const requestQuery = await useRequestQuery(
          "chart-searches",
          profile.attributes.profile_search_text,
          profile.id,
        );
        const options: RequestOptions = {
          method: "POST",
          body: requestQuery,
        };
        const resp = await useApiCall("/chart_searches", options);

        if (resp) {
          this.dashboardChartsData.push(resp.data._rawValue.data);
        }
      }

      this.compileChartsData();
    },

    resetToDefault() {
      this.shareOfVoiceData = [];
      this.shareOfVoiceTotal = 0;
      this.shareOfVoiceOverTimeData = [];
      this.comparisonCoverageData = [];
      this.totalSentiment = { negative: 0, positive: 0, neutral: 0 };
      this.sentimentLineChartData = [];
      this.shareOfReachData = [];
      this.shareOfReachOverTimeData = [];
      this.shareOfAmplificationData = [];
      this.shareOfAmpOverTimeData = [];
      this.articleCountByMediaTypeData = {
        print: 0,
        radio: 0,
        tv: 0,
        web: 0,
      };
      this.reachByMediaTypeData = {
        print: 0,
        radio: 0,
        tv: 0,
        web: 0,
      };
    },

    compileChartsData() {
      const currentProfile = JSON.parse(
        localStorage.getItem("currentProfile") || "{}",
      );

      this.dashboardChartsData.forEach(
        (profileChartsData: MentionChartData) => {
          const savedSearchStore = useSearchStore();
          const profileId = profileChartsData.relationships.profile.data.id;
          const matchingProfile = savedSearchStore.nestedSearches.find(
            (search) => search.id === profileId,
          );
          const profileName = matchingProfile?.attributes.name || "";

          const currentProfileSentiments =
            profileChartsData?.attributes?.search_result?.counts
              .article_sentiment;
          const currentProfileReach =
            profileChartsData?.attributes?.search_result?.counts.reach;
          const currentProfileSocial =
            profileChartsData?.attributes?.search_result?.counts.social;
          const currentProfileArticles =
            profileChartsData?.attributes?.search_result?.counts?.articles;
          const currentMediaTypeData =
            profileChartsData?.attributes?.search_result?.counts_by_media_type;
          const currentMentionTotal =
            profileChartsData?.attributes?.search_result?.total || 0;

          this.handleComparisonCoverage(profileName, currentProfileArticles);

          this.chartDataBuilder(profileName, currentProfileSocial, "Amp");
          this.chartDataBuilder(profileName, currentProfileReach, "Reach");
          this.chartDataBuilder(
            profileName,
            currentProfileArticles,
            "Voice",
            currentMentionTotal,
          );

          if (
            currentProfile?.data?.id ===
            profileChartsData?.relationships?.profile?.data?.id
          ) {
            // Include media type and sentiment data only for the current profile in the charts.
            this.handleMediaTypes(currentMediaTypeData);
            this.handleSentiments(currentProfileSentiments);
          }
        },
      );
    },

    handleMediaTypes(currentMediaTypeData: CountByMediaType) {
      for (const mediaTypeKey in currentMediaTypeData) {
        const mediaType = currentMediaTypeData[mediaTypeKey];
        const articleCount = mediaType.articles.reduce(
          (sum: number, curr: ArticleChartData) => {
            return sum + curr.total;
          },
          0,
        );
        const articleReach = mediaType.reach.reduce(
          (sum: number, curr: ArticleChartData) => {
            return sum + curr.total;
          },
          0,
        );
        this.reachByMediaTypeData[mediaTypeKey] += articleReach;
        this.articleCountByMediaTypeData[mediaTypeKey] += articleCount;
      }
    },

    chartDataBuilder(
      profileName: string,
      dataArray: Array<ArticleChartData>,
      type: string,
      mentionCount: number = 0,
    ) {
      let total = 0;
      const compiledData: Array<Array<number>> = [];
      dataArray?.forEach((item: ArticleChartData) => {
        compiledData.push([Date.parse(item.date), item.total]);
        total += item.total;
      });
      switch (type) {
        case "Amp":
          // handle Amplification
          this.shareOfAmpOverTimeData.push({
            data: compiledData,
            name: profileName,
            type: "column",
          });
          this.shareOfAmplificationData.push({
            name: profileName,
            y: total,
          });
          break;
        case "Reach":
          // handle Reach
          this.shareOfReachOverTimeData.push({
            data: compiledData,
            name: profileName,
            type: "column",
          });
          this.shareOfReachData.push({
            name: profileName,
            y: total,
          });
          break;
        case "Voice":
          // handle Share Of Voice
          this.shareOfVoiceOverTimeData.push({
            data: compiledData,
            name: profileName,
            type: "column",
          });
          this.shareOfVoiceData.push({
            name: profileName,
            y: mentionCount,
          });
          this.shareOfVoiceTotal += mentionCount || 0;
          break;
      }
    },

    handleComparisonCoverage(
      profileName: string,
      articles: Array<ArticleChartData>,
    ) {
      const parsedArticles = articles.map(
        ({ total, date }: { total: number; date: string }) => [
          Date.parse(date),
          total,
        ],
      );
      this.comparisonCoverageData.push({
        type: "line",
        name: profileName,
        data: parsedArticles,
      });
    },

    handleSentiments(
      currentProfileSentiments: Array<ArticleSentimentChartData>,
    ) {
      currentProfileSentiments.forEach((sentiment) => {
        const matchingSentimentObject = this.sentimentLineChartData.find(
          (data: ArticleSentimentChartData) =>
            Date.parse(data.date) === Date.parse(sentiment.date),
        );
        if (matchingSentimentObject) {
          matchingSentimentObject.positive += sentiment.positive;
          matchingSentimentObject.negative += sentiment.negative;
          matchingSentimentObject.neutral += sentiment.neutral;
        } else {
          this.sentimentLineChartData.push({
            date: Date.parse(sentiment.date),
            positive: sentiment.positive,
            negative: sentiment.negative,
            neutral: sentiment.neutral,
          });
        }

        // Compiling Sentiment Totals
        this.totalSentiment.positive += sentiment.positive;
        this.totalSentiment.negative += sentiment.negative;
        this.totalSentiment.neutral += sentiment.neutral;
      });
    },
  },
});
