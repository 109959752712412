import { defineStore } from "pinia";
import { useApiCall } from "../composables/useApiCall";
import { getExcludedDomainsRequestParams } from "../utils/exclusionUtils";
import { useSearchStore } from "./search";

export const useExclusion = defineStore("exclusion", {
  state: () => ({
    pubs: [],
    excludedDomains: [],
  }),
  actions: {
    setPubs(pubs: any) {
      this.pubs = pubs;
    },
    resetPubs() {
      this.pubs = [];
    },
    addExcludedDomain(excludedDomain: any) {
      this.excludedDomains.push(excludedDomain);
    },
    async excludePubs() {
      try {
        const responses = await Promise.all(
          this.pubs.map((pub) =>
            useApiCall(
              "/excluded_domains",
              getExcludedDomainsRequestParams(pub),
            ),
          ),
        );
        const isSuccess = responses.every(
          (response) => !response?.error?.value,
        );

        let errMsg = "manage.mentionsAndPublications.excludePublicationFailure";
        const errors = responses
          .filter((response) => response?.error?.value)
          .map((result) => {
            return result.error.value.cause.data.errors[0].code;
          });
        if (
          errors.includes(
            "you_have_reached_the_excluded_domain_limit_for_your_plan",
          )
        ) {
          errMsg =
            "manage.mentionsAndPublications.excludePublicationLimitError";
        }

        if (isSuccess) {
          this.pubs.forEach((pub) => {
            this.addExcludedDomain({
              type: "excluded_domain",
              attributes: {
                media_type: pub.media_type,
                url: pub.url,
              },
            });
          });
          return { isSuccess };
        } else {
          return { isSuccess: false, errMsg };
        }
      } catch (e) {
        console.log(`[exclusion.ts] excludePubs: ${e}`);
      } finally {
        this.resetPubs();
      }
    },
    getPubsfromIds(ids: Set<string>) {
      const searchStore = useSearchStore();
      const idArrays = Array.from(ids);

      const filteredMentions = searchStore.mentions.filter((mention) =>
        idArrays.includes(mention.id),
      );

      const uniqueResults = new Set();
      const uniqueArray = [];

      for (const mention of filteredMentions) {
        const item = { media_type: mention.media_type, url: mention.domain };
        const key = `${item.media_type}-${item.url}`;

        if (!uniqueResults.has(key)) {
          uniqueResults.add(key);
          uniqueArray.push(item);
        }
      }

      return uniqueArray;
    },
  },
});
