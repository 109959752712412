export const getExcludedDomainsRequestParams = (publication: any) => {
  const currentSearchDashboard = JSON.parse(
    localStorage.getItem("currentSearchDashboard") as string,
  );
  const currentProfile = JSON.parse(
    localStorage.getItem("currentProfile") as string,
  );
  return {
    method: "POST",
    body: {
      data: {
        type: "excluded-domains",
        attributes: {
          media_type: publication.media_type,
          url: publication.url,
        },
        relationships: {
          profile: {
            data: {
              type: "profiles",
              id: currentProfile.data.id,
            },
          },
          search_dashboard: {
            data: {
              type: "search-dashboards",
              id: currentSearchDashboard.data.id,
            },
          },
        },
      },
    },
  };
};
